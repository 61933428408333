//
// toast.scss
//


// toast
// .toast { 
//     &:not(:last-child) {
//       margin-bottom: $toast-padding-x;
//     }
// }

// @mixin toast-border-variant($bg) {
//     .toast-body{
//         color: $bg;
//         border-bottom: 3px solid $bg;
//     }
// }

// @each $color, $value in $theme-colors {
//     .toast-border-#{$color} {
//         @include toast-border-variant($value);
//     }
// }

.toast-success {
    background-color: #51A351 !important;
}

.toast-info {
    background-color: #2f96b4 !important;
}

.toast-warning {
    background-color: #f89406 !important;
}

.toast-error {
    background-color: #bd362f !important;
}

#toast-container > div {
    box-shadow: none;
    opacity: 1;
  }
  